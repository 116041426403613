// Generated by Framer (d2b7042)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
import * as sharedStyle from "../css/gKncX3vR1";
const MaterialFonts = getFonts(Material);
const MaterialControls = getPropertyControls(Material);

const cycleOrder = ["hgl_4JC8N"];

const serializationHash = "framer-UZLLP"

const variantClassNames = {hgl_4JC8N: "framer-v-at00j5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, icon, id, link, title, width, ...props}) => { return {...props, BUisCPEGo: link ?? props.BUisCPEGo, PARnD9hvv: title ?? props.PARnD9hvv ?? "Twitter", rq1ex1FGg: icon ?? props.rq1ex1FGg ?? "Twitter"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;icon?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PARnD9hvv, rq1ex1FGg, BUisCPEGo, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "hgl_4JC8N", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Link href={BUisCPEGo} openInNewTab><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-at00j5", className, classNames)} framer-1hxchyu`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"hgl_4JC8N"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-wfqsdl-container"} layoutDependency={layoutDependency} layoutId={"K9xVMMXoR-container"}><Material color={"var(--token-3769711a-c3d5-444b-8a14-c0d7e2492513, rgb(77, 77, 77))"} height={"100%"} iconSearch={"Home"} iconSelection={rq1ex1FGg} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"K9xVMMXoR"} layoutId={"K9xVMMXoR"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-ksm52r"} data-styles-preset={"gKncX3vR1"}>Twitter</motion.p></React.Fragment>} className={"framer-1gdjf3e"} data-framer-name={"Stack"} layoutDependency={layoutDependency} layoutId={"nvFhccRFL"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={PARnD9hvv} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-UZLLP[data-border=\"true\"]::after, .framer-UZLLP [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UZLLP.framer-1hxchyu, .framer-UZLLP .framer-1hxchyu { display: block; }", ".framer-UZLLP.framer-at00j5 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-UZLLP .framer-wfqsdl-container { flex: none; height: 19px; position: relative; width: 19px; }", ".framer-UZLLP .framer-1gdjf3e { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-UZLLP.framer-at00j5 { gap: 0px; } .framer-UZLLP.framer-at00j5 > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-UZLLP.framer-at00j5 > :first-child { margin-left: 0px; } .framer-UZLLP.framer-at00j5 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 104
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"PARnD9hvv":"title","rq1ex1FGg":"icon","BUisCPEGo":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerM8TPi53pU: React.ComponentType<Props> = withCSS(Component, css, "framer-UZLLP") as typeof Component;
export default FramerM8TPi53pU;

FramerM8TPi53pU.displayName = "Social Link";

FramerM8TPi53pU.defaultProps = {height: 19, width: 104};

addPropertyControls(FramerM8TPi53pU, {PARnD9hvv: {defaultValue: "Twitter", displayTextArea: false, title: "Title", type: ControlType.String}, rq1ex1FGg: MaterialControls?.["iconSelection"] && {...MaterialControls["iconSelection"], defaultValue: "Twitter", description: undefined, hidden: undefined, title: "Icon"}, BUisCPEGo: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerM8TPi53pU, [...MaterialFonts, ...sharedStyle.fonts])